<template>
  <div>
    <div v-if="ticket.status==='Canceled'" class="text-danger text-center">ยกเลิก</div>
    <div v-else-if="ticket.status==='Processing'" class="text-info text-center">กำลังออกผล</div>
    <div v-else-if="ticket.status==='Paying'" class="text-success text-center">กำลังจ่ายเงิน</div>
    <div v-else-if="ticket.isWon===true" class="text-success text-right">{{ticket.summary.won | amountFormat(2)}}</div>
    <div v-else-if="ticket.isWon===false" class="text-danger text-center">ไม่ถูกรางวัล</div>
  </div>
</template>
<script>
export default {
  name: 'TicketStatus',
  props: ['ticket']
}
</script>
