<template>
  <div>
    <b-card-body class="p-0 tickets-table">
      <div v-for="market in markets" v-if="market.items.length">
        <h5 class="pt-3 px-2 mb-1 text-info">[{{market.group.groupTitle}}] - {{market.marketTitle}}</h5>
        <table class="table table-sm table-bordered table-hover mb-0">
          <thead>
            <tr class="bg-info">
              <th colspan="2">เลขที่</th>
              <th width="160">วันที่</th>
              <th width="220">ชนิดหวย</th>
              <th width="120">งวด</th>
              <th width="70">รายการ</th>
              <th width="100">ยอด</th>
              <th width="100">ส่วนลด</th>
              <th width="100">รวม</th>
              <th width="100">ถูกรางวัล</th>
              <th>หมายเหตุ</th>
              <th width="50"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in market.items"
              :key="item._id"
              :class="[
                {'alert-danger': item.status==='Canceled'},
                {'alert-warning': item.status==='Refund'},
                {'alert-success': item.isWon===true}
              ]">
              <td width="20" style="border-right: 0">
                <i class="fas fa-times text-danger" v-if="item.status==='Canceled'"></i>
                <i class="fas fa-undo text-warning fa-sm" v-if="item.status==='Refund'"></i>
              </td>
              <td width="80" class="text-center" style="border-left: 0">{{item.ticketNo}}</td>
              <td class="text-center">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
              <td>[{{item.market.groupTitle}}] - {{item.market.marketTitle}}</td>
              <td class="text-center">{{item.round.roundDate}}</td>
              <td class="text-center">{{item.summary.length}}</td>
              <td class="text-right text-info">{{item.summary.amount | amountFormat(2)}}</td>
              <td class="text-right text-danger">{{item.summary.discount | amountFormat(2)}}</td>
              <td class="text-right text-primary">{{item.summary.credit | amountFormat(2)}}</td>
              <td>
                <TicketStatus :ticket="item" />
              </td>
              <td class="text-center">
                <span v-if="item.remarks">
                  <i class="fas fa-search-plus text-info" style="cursor: pointer;" @click="$emit('search', item.remarks)"></i> {{item.remarks}}
                </span>
              </td>
              <td class="text-center">
                <button class="btn btn-outline-info btn-sm" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
              </td>
            </tr>
            <tr v-if="!market.items.length">
              <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
          <tfoot v-if="market.items.length">
            <tr class="alert-info">
              <th colspan="5" class="text-center">รวม</th>
              <th class="text-center">{{market.sum.length}}</th>
              <th class="text-right text-info">{{market.sum.amount | amountFormat(2, '0.00')}}</th>
              <th class="text-right text-danger">{{market.sum.discount | amountFormat(2, '0.00')}}</th>
              <th class="text-right text-primary">{{market.sum.credit | amountFormat(2, '0.00')}}</th>
              <th class="text-right text-success">{{market.sum.won | amountFormat(2, '0.00')}}</th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="!items.length" class="p-3 text-center">ไม่มีข้อมูล</div>
    </b-card-body>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import ViewTicketModal from '@/views/report/components/ViewTicketModal'
import _ from 'lodash'
import TicketStatus from './components/TicketStatus'

export default {
  name: 'TicketByType',
  components: {
    ViewTicketModal,
    TicketStatus
  },
  props: ['items'],
  data() {
    return {
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups || []
    },
    markets() {
      const markets = this.$store.state.Merkets.map((market)=>{

        market.group = this.groups.find((group)=>{
          return group._id === market.groupId
        })

        const items = this.items.filter((item)=>{
          return item.market.marketId === market._id
        })

        market.sum = items.reduce((sum, item)=>{
          if(item.status !== 'Canceled' && item.status !== 'Refund') {
            sum.amount += (item?.summary?.amount || 0)
            sum.credit += (item?.summary?.credit || 0)
            sum.discount += (item?.summary?.discount || 0)
            sum.length += (item?.summary?.length || 0)

            if(item.isWon && item.status === 'Completed')
              sum.won += (item?.summary?.won || 0)
          }

          return sum
        }, {
          amount: 0,
          credit: 0,
          discount: 0,
          won: 0,
          length: 0
        })
        market.items = items

        return market
      })

      return _.orderBy(markets, ['group.sort', 'sort'], ['asc', 'asc'])
    }
  },
  methods: {
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    }
  }
}
</script>
<style lang="scss" scoped>
.tickets-table {
  background-color: #FFF;
  min-height: 50px;

  table {
    border: 0;
    thead {
      tr {
        th {
          font-size: 85%;
          vertical-align: middle;
          text-align: center;
          // border-top: 0;
        }
        th:first-child {
          border-left: 0;
        }
        th:last-child {
          border-right: 0;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          font-size: 85%;
        }
        td:first-child {
          border-left: 0;
        }
        td:last-child {
          border-right: 0;
        }
      }
    }
    tfoot {
      tr {
        th:first-child {
          border-left: 0;
        }
        th:last-child {
          border-right: 0;
        }
      }
    }
  }
}
</style>
